import React from 'react'
import Particles from 'react-tsparticles'
import classnames from 'classnames'

import Button from '../Button/Button'

import { section, title, container } from './NotFound.module.scss'
import { particles } from '../HomeHero/HomeHero.module.scss'

const NotFound = ({ ...props }) => {
  const particlesInit = (main) => {}
  const particlesLoaded = (container) => {}

  return (
    <div className={section} {...props}>
      <div className={classnames('container', container)}>
        <h1 className={title}>Page not found</h1>
        <Button link={'/'} secondary>
          Home page
        </Button>
      </div>
      <Particles
        className={particles}
        id="tsparticles-404"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: {
            enable: false,
          },
          background: {
            color: {
              value: 'none',
            },
          },
          fpsLimit: 60,
          interactivity: {
            detectsOn: 'window',
            events: {
              onClick: {
                enable: true,
                mode: 'push',
              },
              onHover: {
                enable: true,
                mode: 'repulse',
              },
            },
            modes: {
              bubble: {
                distance: 400,
                duration: 2,
                opacity: 0.8,
                size: 40,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: '#ffffff',
            },
            links: {
              color: '#ffffff',
              distance: 100,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: 'none',
              enable: true,
              outMode: 'bounce',
              random: true,
              speed: 3,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 400,
              },
              value: 40,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: 'circle',
            },
            size: {
              value: 2,
            },
          },
          detectRetina: true,
        }}
      />
    </div>
  )
}

export default NotFound
