import * as React from 'react'

import NotFound from '../components/NotFound/NotFound'

// markup
const NotFoundPage = () => {
  return <NotFound />
}

export default NotFoundPage
